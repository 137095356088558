/**
 * @namespace helpers
 */

export default (field, reverse, primer) => {
    /**
     * @method sortBy
     * @description Returns a function that can be passed to Array.sort to sort arrays of objects in real time
     *
     * @memberof helpers
     * @author Stack Overflow
     * @returns {function}
     *
     * @param {string} field - name of the property to sort by
     * @param {boolean} reverse - reverses the sort direction (largest to smallest or Z-A)
     * @param {function=} primer - primer function that can cast the internal value to another type for the purposes of sorting, e.g. currency string to float
     *
     * @example
     * return testData.sort(sortBy('id', false, parseInt));
     *
     */

    let key = primer
        ? x => {
              return primer(x[field]);
          }
        : x => {
              return x[field];
          };

    reverse = !reverse ? 1 : -1;

    return (a, b) => {
        return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
};
